import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import confetti from 'canvas-confetti';
import ReactGA from 'react-ga4';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const SuccessContainer = styled.div`
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

const SuccessIcon = styled.div`
    font-size: 80px;
    color: #4CAF50;
    margin-bottom: 20px;
`;

const Title = styled.h2`
    color: #333;
    margin-bottom: 20px;
`;

const Message = styled.p`
    color: #666;
    font-size: 18px;
    margin-bottom: 30px;
`;

const DetailItem = styled.div`
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
`;

const DetailLabel = styled.span`
    font-weight: bold;
    color: #555;
`;

const DetailValue = styled.span`
    color: #333;
`;

const Button = styled(Link)`
    display: inline-block;
    background-color: #efbf04;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
        background-color: #efbf04;
    }

    &.pulsing {
        animation: ${pulse} 1.5s infinite;
    }
`;

const Success = () => {
    useEffect(() => {
        // Fire confetti animation
        confetti({
            particleCount: 300,
            spread: 100,
            origin: { y: 0.6 }
        });

        // Send purchase event to Google Analytics
        ReactGA.event('purchase', {
            transaction_id: 'guest_purchase', // Since we don't have a real transaction ID
            currency: 'USD',
            value: 2.99, // Amount is unknown without session details
            items: [{
                item_name: 'Guest Token',
                item_id: 'guest_token',
                price: 2.99, // Amount is unknown without session details
            }],
        });
    }, []);

    return (
        <SuccessContainer>
            <SuccessIcon>🎉</SuccessIcon>
            <Title>Payment Successful!</Title>
            <Message>Thank you for your purchase! Your question will be answered shortly, and you will receive an email with the response. Thanks again!</Message>

            
            <DetailItem>
                <DetailLabel>Amount Paid: </DetailLabel>
                <DetailValue>${"2.99"}</DetailValue>
            </DetailItem>

            <Button to="/guest-question" style={{ marginRight: '5px' }}>Submit a new question</Button>
            <Button to="/la-c-nads" className="pulsing">Create a new account!</Button>
        </SuccessContainer>
    );
};

export default Success;
