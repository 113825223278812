import React, { useState } from 'react';
import { Modal, Card, Row, Col, Nav, Tab, Table, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';

const controlTypes = {
  1: "public",
  2: "private nonprofit",
  3: "private for-profit",
  4: "foreign"
};

const stateNames = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  DC: "District of Columbia",
};

const ProfileCollegeDetails = ({ show, onHide, college }) => {
  const [activeTab, setActiveTab] = useState('admission');
  

  if (!college) {
    return null; // Render nothing if college is null
  }

  const earningsChartOptions = {
    chart: {
      type: 'area',
      height: 400,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: 'Median Earnings After Entry',
      align: 'center',
    },
    xaxis: {
      categories: ['6 years', '8 years', '10 years'],
    },
    yaxis: {
      title: {
        text: 'Earnings (USD)',
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    tooltip: {
      x: {
        format: 'years',
      },
    },
  };

  const earningsChartData = [
    {
      name: 'Median Earnings',
      data: [
        college.MD_EARN_WNE_P6 || 0,
        college.MD_EARN_WNE_P8 || 0,
        college.MD_EARN_WNE_P10 || 0,
      ],
    },
  ];

  const diversityChartOptions = {
    chart: {
      type: 'pie',
      height: 350,
    },
    labels: ['Black', 'White', 'Hispanic', 'Asian', 'American Indian / Alaskan Native', 'Native Hawaiian / Pacific Islander'],
    colors: ['#ADD8E6', '#66CDAA', '#FFE4B5', '#FF7F50', '#FFB6C1', '#48D1CC'],
    title: {
      text: 'Race & Ethnicity Diversity',
      align: 'center',
    },
    legend: {
      position: 'bottom',
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value.toFixed(2) + '%'; // Ensures the value is always shown with two decimal places followed by a percentage sign
        }
      }
    },
    dataLabels: {
      formatter: function (value, { seriesIndex, w }) {
        return value.toFixed(2) + '%'; // Ensures the value on the slice is always shown with two decimal places followed by a percentage sign
      }
    }
  };

  const diversityChartSeries = [
    parseFloat((college.UGDS_BLACK * 100 || 0).toFixed(2)),
    parseFloat((college.UGDS_WHITE * 100 || 0).toFixed(2)),
    parseFloat((college.UGDS_HISP * 100 || 0).toFixed(2)),
    parseFloat((college.UGDS_ASIAN * 100 || 0).toFixed(2)),
    parseFloat((college.UGDS_AIAN * 100 || 0).toFixed(2)),
    parseFloat((college.UGDS_NHPI * 100 || 0).toFixed(2)),
  ];

  return (
    <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {college.COLLEGE_NAME_1 || college.INSTNM}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <h2>{college.COLLEGE_NAME_1 || college.INSTNM}</h2>
                <p>{college.CITY}, {stateNames[college.STABBR] || "N/A"}</p>
                <h5>Overview</h5>
                <p>{college.COLLEGE_NAME_1 || college.INSTNM} is a {controlTypes[college.CONTROL] || "N/A"} institution in {stateNames[college.STABBR] || "N/A"} with {college.UGDS || "N/A"} undergraduate students.</p>
                <h6>At a glance:</h6>
                <ul>
                  <li>Acceptance rate: {(college.ADM_RATE ? (college.ADM_RATE * 100).toFixed(1) : "N/A")}%</li>
                  <li>Median average net price: ${college.NPT4_PUB || college.NPT4_PRIV || "N/A"}</li>
                  <li>Average SAT: {college.SAT_AVG || "N/A"}</li>
                  <li>Average ACT: {college.ACTCMMID || "N/A"}</li>
                  <li>Median Loan amount: ${college.loan_principal || "N/A"}</li>
                  <li>Students who received a federal loan while in school: {college.students_with_any_loan || "N/A"}%</li>
                  <li>Average family income: ${college.demographics_avg_family_income || "N/A"}</li>
                </ul>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-disclaimer">
                      The information provided is subject to change and should be verified with the college. Source: DoE College Scorecard
                    </Tooltip>
                  }
                >
                  <p style={{ cursor: "pointer", textDecoration: "underline", display: 'inline-block', marginBottom: 0 }}>Disclaimer</p>
                </OverlayTrigger>
                <p style={{ marginTop: 0 }}>
                  <a href="support" style={{ textDecoration: "underline" }}>Report a mistake in the data</a>
                </p>
              </Col>
              <Col md={8}>
                <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                  <Nav variant="tabs" className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="admission">Admission</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="financial">Financial Data</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="profile">Class Profile</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="admission">
                      <h5>Admissions stats</h5>
                      <Row>
                        <Col md={6}>
                          <h6>Applicants & acceptance rate</h6>
                          <p>Overall Acceptance Rate: {(college.ADM_RATE ? (college.ADM_RATE * 100).toFixed(1) : "N/A")}%</p>
                          <p>Open Admission Policy: {college.OPENADMP ? 'Yes' : 'No'}</p>
                          <p>Test Policy: {college.ADMCON7 || "N/A"}</p>
                        </Col>
                        <Col md={6}>
                          <h6>Accepted students who attend</h6>
                          <p>Average SAT Score: {college.SAT_AVG || "N/A"}</p>
                          <p>ACT 25th Percentile: {college.ACTCM25 || "N/A"}</p>
                          <p>ACT 75th Percentile: {college.ACTCM75 || "N/A"}</p>
                          <p>Average ACT Score: {college.ACTCMMID || "N/A"}</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={6}>
                          <h6>SAT Percentiles</h6>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Section</th>
                                <th>25th Perc.</th>
                                <th>75th Perc.</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Critical Thinking</td>
                                <td>{college.SATVR25 || "N/A"}</td>
                                <td>{college.SATVR75 || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Math</td>
                                <td>{college.SATMT25 || "N/A"}</td>
                                <td>{college.SATMT75 || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Writing</td>
                                <td>{college.SATWR25 || "N/A"}</td>
                                <td>{college.SATWR75 || "N/A"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col md={6}>
                          <h6>ACT Percentiles</h6>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Section</th>
                                <th>25th Perc.</th>
                                <th>75th Perc.</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>English</td>
                                <td>{college.ACTEN25 || "N/A"}</td>
                                <td>{college.ACTEN75 || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Math</td>
                                <td>{college.ACTMT25 || "N/A"}</td>
                                <td>{college.ACTMT75 || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Writing</td>
                                <td>{college.ACTWR25 || "N/A"}</td>
                                <td>{college.ACTWR75 || "N/A"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="financial">
                      <h5>Cost & scholarships</h5>
                      <Row>
                        <Col md={6}>
                          <Table striped bordered hover size="sm">
                            <tbody>
                              <tr>
                                <td>In-state total</td>
                                <td>${college.TUITIONFEE_IN || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Out-of-state</td>
                                <td>${college.TUITIONFEE_OUT || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Average Net Price</td>
                                <td>${college.NPT4_PUB || college.NPT4_PRIV || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Average Room, Books, and other charges</td>
                                <td>${college.FORMULA || "N/A"}</td>
                              </tr>
                            </tbody>
                          </Table>
                          <p className="text-muted">Published costs and averages can be misleading; they don't account for your family's finances or your academic profile</p>
                          <div className="mt-3">
                            <p>Average Location's $/Foot: ${college.SX_XX || "N/A"}</p>
                            <p>Cumulative median student debt: ${college.DEBT_MDN || "N/A"}</p>
                            <p>Average Debt for Student that have completed: ${college.GRAD_DEBT_MDN || "N/A"}</p>
                            <p>Average Loan Principal: ${college.DEBT_MDN || "N/A"}</p>
                            <p>Loan Default Rate: {college.CDR3 || "N/A"}%</p>
                          </div>
                        </Col>
                        <Col md={6}>
                          <p>Students in the FAFSA applications: {college.APPL_SCH_N || "N/A"}</p>
                          <p>Percent of full-time students that have a Federal loan: {college.FTFTPCTFLOAN ? (college.FTFTPCTFLOAN * 100).toFixed(2) : "N/A"}%</p>
                          <p>Median earnings 6 years after entry: ${college.MD_EARN_WNE_P6 || "N/A"}</p>
                          <div style={{ height: '300px' }}>
                            <ReactApexChart options={earningsChartOptions} series={earningsChartData} type="area" height="100%" width="100%" />
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="profile">
                      <h5>Enrolled students</h5>
                      <Row>
                        <Col md={6}>
                          <p>First-year students (full-time): {college.UGDS || "N/A"}</p>
                          <p>Percentage of First Generation Students: {college.PAR_ED_PCT_1STGEN ? (college.PAR_ED_PCT_1STGEN * 100).toFixed(2) + "%" : "N/A"}</p>
                          <Row>
                            <Col md={6} style={{ width: '48%' }}>
                              <p>Enrolled women: <br /> {(college.UGDS_WOMEN * 100).toFixed(2) || "N/A"}%</p>
                              <ProgressBar now={(college.UGDS_WOMEN || 0) * 100} label={`${(college.UGDS_WOMEN * 100).toFixed(2)}%`} />
                            </Col>
                            <Col md={6} style={{ width: '48%' }}>
                              <p>Enrolled men: <br /> {(college.UGDS_MEN * 100).toFixed(2) || "N/A"}%</p>
                              <ProgressBar now={(college.UGDS_MEN || 0) * 100} label={`${(college.UGDS_MEN * 100).toFixed(2)}%`} />
                            </Col>
                          </Row>
                          <h6 className="mt-4">Completion Rate</h6>
                          <ProgressBar now={(college.C100_4 ? (college.C100_4 * 100).toFixed(2) : 0)} label={`${college.C100_4 ? (college.C100_4 * 100).toFixed(2) : "0.00"}%`} />
                          <h6 className="mt-4">Retention Rate</h6>
                          <ProgressBar now={(college.RET_FT4_POOLED ? (college.RET_FT4_POOLED * 100).toFixed(2) : 0)} label={`${college.RET_FT4_POOLED ? (college.RET_FT4_POOLED * 100).toFixed(2) : "0.00"}%`} />
                          <p className="mt-3">Most popular major: {college.name_major || "N/A"} with {college.percentage || "N/A"}% of degrees awarded</p>
                          <p>Share of Non-Resident Aliens: {college.UGDS_NRA ? (college.UGDS_NRA * 100).toFixed(2) + "%" : "N/A"}</p>
                        </Col>
                        <Col md={6}>
                          <p>Average Household Income: ${college.MEDIAN_HH_INC || "N/A"}</p>
                          <p>Unemployment Rate: {(college.UNEMP_RATE !== undefined && college.UNEMP_RATE !== null) ? college.UNEMP_RATE.toFixed(2) : "N/A"}%</p>
                          <h6>Race & Ethnicity Diversity Among Domestic Students</h6>
                          <div style={{ position: "relative", width: "100%", height: 350 }}>
                            <ReactApexChart options={diversityChartOptions} series={diversityChartSeries} type="pie" height="100%" width="100%" />
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileCollegeDetails;
