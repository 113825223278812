import React, { useState, useEffect } from 'react';
import { Card, ListGroup, Button, Modal, Form, Offcanvas, Row, Col } from 'react-bootstrap';
import Avatar from './Avatar';
import axios from 'axios';

export default function Friends() {
  const [friends, setFriends] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newFriendEmail, setNewFriendEmail] = useState('');
  const [showRequests, setShowRequests] = useState(false);
  const [friendRequests, setFriendRequests] = useState([]);
  const [showAllFriends, setShowAllFriends] = useState(false);

  useEffect(() => {
    fetchFriends();
  }, []);

  const fetchFriends = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("/friends", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFriends(response.data);
    } catch (error) {
      console.error("Error fetching friends:", error);
    }
  };

  const fetchFriendRequests = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("/friends/requests", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFriendRequests(response.data);
    } catch (error) {
      console.error("Error fetching friend requests:", error);
    }
  };

  const handleSendFriendRequest = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken");
      await axios.post("/friends/request", { receiver_email: newFriendEmail }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowModal(false);
      setNewFriendEmail('');
      alert('Friend request sent successfully!');
      fetchFriends();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert('Friend request already exists or you are already friends.');
      } else {
        console.error("Error sending friend request:", error);
        alert('Failed to send friend request. Please try again.');
      }
    }
  };

  const handleAcceptFriendRequest = async (requestId) => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(`/friends/accept/${requestId}`, null, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchFriendRequests();
      fetchFriends();  // Refresh the list after accepting
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  };

  const handleDeclineFriendRequest = async (requestId) => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(`/friends/decline/${requestId}`, null, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchFriendRequests();
    } catch (error) {
      console.error("Error declining friend request:", error);
    }
  };

  const handleShowRequests = () => {
    fetchFriendRequests(); // Load friend requests when opening the panel
    setShowRequests(true);
  };

  const handleCloseRequests = () => setShowRequests(false);

  const toggleShowAllFriends = () => {
    setShowAllFriends(!showAllFriends);
  };

  const visibleFriends = showAllFriends ? friends : friends.slice(0, 5);

  return (
    <>
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>Friends</Card.Title>
        </Card.Header>
        <Card.Body>
          <ListGroup>
            {visibleFriends.map((friend) => (
              <ListGroup.Item key={friend.id} className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Avatar img={friend.avatar} />
                  <span className="ms-2">{friend.name}</span>
                </div>
                <Button variant="outline-primary" size="sm">Message</Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
          {friends.length > 5 && (
            <Button variant="link" className="w-100 mt-2" onClick={toggleShowAllFriends}>
              {showAllFriends ? 'Show Less' : 'Show More'}
            </Button>
          )}
          <Row className="mt-3">
            <Col>
              <Button variant="primary" className="w-100" onClick={() => setShowModal(true)}>
                Add Friend
              </Button>
            </Col>
            <Col>
              <Button variant="secondary" className="w-100" onClick={handleShowRequests}>
                Show Requests
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Modal for adding a new friend */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Friend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSendFriendRequest}>
            <Form.Group className="mb-3">
              <Form.Label>Friend's Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter friend's email"
                value={newFriendEmail}
                onChange={(e) => setNewFriendEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Send Friend Request
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Offcanvas for friend requests */}
      <Offcanvas show={showRequests} onHide={handleCloseRequests} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Friend Requests</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            {friendRequests.map((request) => (
              <ListGroup.Item key={request.id} className="d-flex justify-content-between align-items-center">
                <span>{request.sender.name}</span>
                <div>
                  <Button variant="success" size="sm" className="me-2" onClick={() => handleAcceptFriendRequest(request.id)}>
                    Accept
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleDeclineFriendRequest(request.id)}>
                    Decline
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
