// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, authorizedUserTypes, ...rest }) => {
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) : null;

  if (!Component) {
    console.error("ProtectedRoute received an undefined component");
    return <Navigate to="/signin" />;
  }

  if (!user) {
    return <Navigate to="/signin" />;
  }

  // Check if the user's type is in the list of authorized types
  if (authorizedUserTypes && !authorizedUserTypes.includes(user.user_type)) {
    return <Navigate to="/unauthorized" />; // Redirect to an unauthorized page
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
