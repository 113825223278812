import React, { useState, useEffect } from 'react';
import { Modal, Button, FormCheck, Form, FormControl } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';

const CollegeRounds = ({ collegeId, collegeName, show, handleClose, onSave }) => {
  const [rounds, setRounds] = useState({});
  const [selectedRound, setSelectedRound] = useState('');
  const [customRoundName, setCustomRoundName] = useState('');
  const [customDeadline, setCustomDeadline] = useState(new Date());
  const [error, setError] = useState(null);

  // Fetch rounds for the selected college whenever collegeId changes
  useEffect(() => {
    if (collegeId && show) {
      const user = localStorage.getItem('user');
      if (!user) {
        console.error('User is not authenticated');
        return;
      }
  
      const { token } = JSON.parse(user);
  
      // Reset rounds and selected round whenever a new college is selected
      setRounds({});
      setSelectedRound('');
      setCustomRoundName('');
      setCustomDeadline(new Date());
      setError(null);
  
      // Fetch the rounds for the current college
      axios.get(`https://api.student.youelite.net/api/colleges/${collegeId}/rounds`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setRounds(response.data);
          setError(null);
        })
        .catch((error) => {
          // Check if the error is a 404 with the specific message
          if (error.response && error.response.status === 404 && error.response.data.message === "College not found") {
            setError('We apologize, it is not possible to add a deadline for this college.');
          } else {
            // Default error message for all other cases
            console.error('Error fetching rounds:', error);
            setError('Failed to fetch admission rounds. Please try again.');
          }
        });
    }
  }, [collegeId, show]);
  

  const handleSave = async () => {
    const user = localStorage.getItem('user');
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const { token } = JSON.parse(user);

    if (selectedRound === 'custom') {
      const customEvent = {
        title: `${collegeName} - ${customRoundName}`,
        date: customDeadline.toISOString().split('T')[0],
        category: 'College List Deadlines',
        college: collegeName,
      };

      try {
        await onSave(customEvent, true);
        handleClose();
      } catch (error) {
        console.error('Failed to save custom event:', error);
        setError('Failed to save custom event. Please try again.');
      }
    } else {
      try {
        await axios.post('https://api.student.youelite.net/api/user/colleges/round', {
          college_id: collegeId,
          round: selectedRound,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        onSave(selectedRound, false);
        handleClose();
      } catch (error) {
        console.error('Error saving round:', error);
        setError('Failed to save admission round. Please try again.');
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Admission Round</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        {Object.keys(rounds).length === 0 ? (
          <p>No available rounds for this college.</p>
        ) : (
          <>
            {Object.keys(rounds).map((round) => (
              <FormCheck
                type="radio"
                id={round}
                label={`${round} - ${rounds[round]}`}
                key={round}
                value={round}
                checked={selectedRound === round}
                onChange={(e) => setSelectedRound(e.target.value)}
              />
            ))}
            <FormCheck
              type="radio"
              id="custom"
              label="Custom Deadline"
              value="custom"
              checked={selectedRound === 'custom'}
              onChange={(e) => setSelectedRound(e.target.value)}
            />
            {selectedRound === 'custom' && (
              <>
                <Form.Group controlId="customRoundName">
                  <Form.Label>Round Name</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Enter round name"
                    value={customRoundName}
                    onChange={(e) => setCustomRoundName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="customDeadline">
                  <Form.Label>Deadline Date</Form.Label>
                  <ReactDatePicker
                    selected={customDeadline}
                    onChange={(date) => setCustomDeadline(date)}
                    dateFormat="yyyy/MM/dd"
                    className="form-control"
                  />
                </Form.Group>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CollegeRounds;
