import React, { useState } from "react";
import { Modal, Button, Form, InputGroup, FormControl, ListGroup } from "react-bootstrap";
import axios from "axios";

export default function FileUploadOverlay({ show, handleClose, onUpload, application, onPlanLimitReached }) {
  const [fileName, setFileName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handleUpload = async () => {
    if (!fileName || selectedFiles.length === 0) {
      alert('Please provide a file name and select a file to upload.');
      return;
    }

    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('application_id', application.id);
      formData.append('document_name', fileName);
      formData.append('file', selectedFiles[0]);  // Assuming one file at a time

      const response = await axios.post('/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('File uploaded successfully:', response.data);
      onUpload(response.data);
      handleClose();
    } catch (error) {
      console.error('Error uploading file:', error);

      // Check for 403 error and PLAN_LIMIT_REACHED
      if (error.response && error.response.status === 403) {
        if (error.response.data.error_code === 'PLAN_LIMIT_REACHED') {
          if (onPlanLimitReached) {
            onPlanLimitReached();  // Call the parent function to show the upgrade modal
          }
          handleClose();  // Close the file upload overlay
        }
      } else {
        alert('Failed to upload the file. Please try again.');
      }
    } finally {
      setUploading(false);
    }
  };


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {application && (
          <p>
            Connected to application: <strong>{application.college?.INSTNM || 'Unknown College'}</strong> - {application.round}
          </p>
        )}
        <Form>
          <Form.Group className="mb-3" controlId="formFileName">
            <Form.Label>File Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter file name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formFileUpload">
            <Form.Label>File Upload</Form.Label>
            <InputGroup>
              <FormControl
                type="file"
                onChange={handleFileChange}
                multiple
              />
            </InputGroup>
            <small className="text-muted">Max. 10MB</small>
            {selectedFiles.length > 0 && (
              <ListGroup className="mt-3">
                {selectedFiles.map((file, index) => (
                  <ListGroup.Item key={index}>
                    {file.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Form.Group>

          <Button variant="primary" onClick={handleUpload} disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
