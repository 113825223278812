import React, { useState } from "react";
import { Modal, Button, Form, InputGroup, FormControl, Dropdown, DropdownButton } from "react-bootstrap";
import axios from 'axios';
import debounce from 'lodash.debounce';

export default function NewApplicationOverlay({ show, handleClose, addApplication, onPlanLimitReached }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [major, setMajor] = useState('');
  const [round, setRound] = useState('RA'); // Default to 'RA'
  const [submissionDate, setSubmissionDate] = useState('');
  const [applicationFee, setApplicationFee] = useState('');

  // Fetch college suggestions based on the search query
  const fetchSuggestions = debounce(async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await axios.get(`/colleges?search=${query}`);
      setSuggestions(response.data.data); // Adjust this path based on your API response
    } catch (error) {
      console.error("Failed to fetch suggestions:", error);
    }
  }, 300);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchSuggestions(query);
  };

  const handleSelectCollege = (college) => {
    setSelectedCollege(college);
    setSearchQuery(college.INSTNM); // Show the selected college name in the search bar
    setSuggestions([]); // Hide suggestions after selection
  };

  const handleSelectRound = (roundValue) => {
    setRound(roundValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCollege) {
        alert('Please select a college.');
        return;
    }

    try {
        const response = await axios.post('/applications', {
            unitid: selectedCollege.UNITID,
            major,
            round,
            submission_date: submissionDate,
            application_fee: applicationFee
        });
        
        // Include the full college information in the new application
        const newApplication = {
            ...response.data,
            college: selectedCollege
        };
        
        addApplication(newApplication);
        handleClose();
    } catch (error) {
        // Error handling...
    }
  };


  return (
    <>
      <style>
        {`
          .custom-dropdown-menu {
            position: absolute;
            top: 100%; /* Ensure it appears below the input */
            left: 0;
            right: 0;
            z-index: 1050; /* Higher z-index to ensure it appears above other elements */
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid #ddd;
            background-color: #fff;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            border-radius: 4px;
          }
        `}
      </style>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formCollege">
              <Form.Label>College</Form.Label>
              <InputGroup>
                <FormControl
                  placeholder="Search for a college"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  required
                />
                {suggestions.length > 0 && (
                  <Dropdown.Menu className="custom-dropdown-menu" show>
                    {suggestions.map((suggestion, index) => (
                      <Dropdown.Item key={index} onClick={() => handleSelectCollege(suggestion)}>
                        {suggestion.INSTNM}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                )}
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMajor">
              <Form.Label>Major</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter major"
                value={major}
                onChange={(e) => setMajor(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRound">
              <Form.Label>Round</Form.Label>
              <DropdownButton
                id="dropdown-round"
                title={round || "Select Application Round"}
                onSelect={handleSelectRound}
              >
                <Dropdown.Item eventKey="EAD">Early Decision</Dropdown.Item>
                <Dropdown.Item eventKey="EAA">Early Action</Dropdown.Item>
                <Dropdown.Item eventKey="RA">Regular Admission</Dropdown.Item>
              </DropdownButton>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSubmissionDate">
              <Form.Label>Submission Date</Form.Label>
              <Form.Control
                type="date"
                value={submissionDate}
                onChange={(e) => setSubmissionDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formApplicationFee">
              <Form.Label>Application Fee</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter application fee"
                value={applicationFee}
                onChange={(e) => setApplicationFee(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Create Application
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
