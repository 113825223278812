import React from 'react';
import { Modal, Card } from 'react-bootstrap';

const controlTypes = {
  1: "public",
  2: "private nonprofit",
  3: "private for-profit",
  4: "foreign"
};

const stateNames = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  DC: "District of Columbia",
};

const ProfileCollegeDetails = ({ show, onHide, college }) => {
  if (!college) {
    return null; // Render nothing if college is null
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {college.COLLEGE_NAME_1 || college.INSTNM}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <h5>Overview</h5>
            <p>{college.COLLEGE_NAME_1 || college.INSTNM} is a {controlTypes[college.CONTROL] || "N/A"} institution in {stateNames[college.STABBR] || "N/A"} with {college.UGDS || "N/A"} undergraduate students.</p>
            <h6>At a glance:</h6>
            <ul>
              <li>Acceptance rate: {(college.ADM_RATE ? (college.ADM_RATE * 100).toFixed(1) : "N/A")}%</li>
              <li>Median average net price: ${college.NPT4_PUB || college.NPT4_PRIV || "N/A"}</li>
              <li>Average SAT: {college.SAT_AVG || "N/A"}</li>
              <li>Average ACT: {college.ACTCMMID || "N/A"}</li>
              <li>Median Loan amount: ${college.loan_principal || "N/A"}</li>
              <li>Students who received a federal loan while in school: {college.students_with_any_loan || "N/A"}%</li>
              <li>Average family income: ${college.demographics_avg_family_income || "N/A"}</li>
            </ul>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileCollegeDetails;
