import React, { useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { Dropdown, Row, Col, Nav, Form, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "../components/Avatar";
import axios from "axios";
import '../assets/css/ChatBot.css'
import { marked } from 'marked';


import img14 from "../assets/img/img14.jpg"; // AI assistant avatar
import img16 from "../assets/img/img16.jpg"; // User avatar or other participants

export default function Chat() {
  const chatBodyRef = useRef(null); // Reference for the chat body to scroll

  useEffect(() => {
    document.title = "Chatbot | YouElite";
  }, []);

  const [messageGroup, setMessageGroup] = useState([]); // Keep track of all messages
  const [userMessage, setUserMessage] = useState("");   // Store the user input
  const [msgShow, setMsgShow] = useState(false);        // Show/hide the chat window
  const [isTyping, setIsTyping] = useState(false);      // Simulate typing indicator
  const [chatId, setChatId] = useState(null);           // Store chat_id for future requests

  // Scroll to the bottom of the chat content whenever a new message is added
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current._container.scrollTop = chatBodyRef.current._container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when messages are updated
  }, [messageGroup]);

  useEffect(() => {
    document.body.classList.add('page-app');
    return () => {
      document.body.classList.remove('page-app');
    };
  }, []);

  // Retrieve the user's name from localStorage
  const userName = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : "there";

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="header-link"
    >
      {children}
    </Link>
  ));

  // Function to send the user's message and display it immediately
  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;  // Ignore empty messages

    // Create a temporary message to display immediately
    const tempMessageId = new Date().getTime();
    const newMessageGroup = [
      ...messageGroup,
      {
        id: tempMessageId,
        role: "user",
        text: userMessage,
        time: new Date().toLocaleTimeString(),
        avatar: img16,
        pending: true, // Mark as pending
      }
    ];
    setMessageGroup(newMessageGroup);  // Add temporary message
    setUserMessage("");                // Clear input field

    // Show typing indicator
    setIsTyping(true);

    // Prepare API request payload
    const payload = {
      message: userMessage,
      ...(chatId && { chat_id: chatId }) // Include chat_id if it's set
    };

    // Send message to backend API
    try {
      const response = await axios.post("/chat-ai", payload);
      const newResponse = response.data;

      // If this is the first message, capture the chat_id from the response
      if (!chatId) {
        setChatId(newResponse.chat_id); // Store the new chat_id
      }

      // Convert the API response to message format
      const newMessages = newResponse.display_messages.map((msg) => ({
        role: msg.role,
        text: msg.content,
        time: new Date().toLocaleTimeString(),
        avatar: msg.role === "assistant" ? img14 : img16,
      }));

      // Remove typing indicator and update message group with actual messages
      setIsTyping(false);
      setMessageGroup(newMessages);

    } catch (error) {
      console.error("Error sending message: ", error);
      setIsTyping(false); // Hide typing indicator even in case of error
    }
  };

  // Function to handle the Enter key press to send the message
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className={"chat-panel" + (msgShow ? " msg-show" : "")}>
          <div className="chat-sidebar">
            <div className="sidebar-header">
              <h6 className="sidebar-title me-auto">Chat Messages</h6>
              <Dropdown style={{visibility:'hidden'}} align="end">
                <Dropdown.Toggle as={CustomToggle}>
                  <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href=""><i className="ri-user-add-line"></i> Report an Issue</Dropdown.Item>
                  <Dropdown.Item href=""><i className="ri-bell-line"></i> Notification Settings</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <PerfectScrollbar className="sidebar-body">
              <div className="text-content">
                <h6>Welcome to Nova!</h6>
                <p>Nova is your personal AI assistant, here to guide you through every step of the college admission process. Whether you have questions about applications, essays, financial aid, or anything else related to college, Nova is here to help.</p>
                <p>Feel free to ask anything, and we'll provide the information you need to make informed decisions on your academic journey. Rest assured, your privacy is important to us—Nova does not save your chats, and once you close the page, the conversation is permanently deleted.</p>
              </div>
            </PerfectScrollbar>
          </div>
          <div className="chat-body">
            <div className="chat-body-header">
              <div className="chat-item">
                <Avatar img={img14} status="online" />
                <div className="chat-item-body">
                  <h6 className="mb-1">Nova AI</h6>
                  <span>Active now</span>
                </div>
              </div>
              <Nav className="nav-icon ms-auto">
                <Nav.Link href="" title="Restart Chat" onClick={() => window.location.reload()}>
                  <i className="ri-delete-bin-line"></i>
                </Nav.Link>
                <Nav.Link href="" className="d-md-none" onClick={() => setMsgShow(false)}><i className="ri-close-fill"></i></Nav.Link>
              </Nav>
            </div>
            <PerfectScrollbar className="chat-body-content" ref={chatBodyRef}>
              {messageGroup.length === 0 ? (
                <div className="chat-welcome text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
                  {/* Improved Welcome Design */}
                  <Avatar img={img14} status="online" />
                  <h2 className="mt-4">Hi, {userName}</h2>
                  <p>How can I help you?</p>
                  
                  <div className="mt-3 d-flex flex-wrap gap-3 justify-content-center" style={{ display: "none", maxWidth: "400px" }}>
                    <Row className="w-100 justify-content-center" style={{ display: "none"}}>
                      <Col>
                        <Button variant="primary" size="lg" className="w-100 mb-2 mx-2" style={{ minWidth: '120px', minHeight: '40px' }}>Cerca un'università</Button>
                      </Col>
                      <Col>
                        <Button variant="secondary" size="lg" className="w-100 mb-2 mx-2" style={{ minWidth: '120px', minHeight: '40px' }}>Chiedi delle borse di studio</Button>
                      </Col>
                    </Row>
                    <Row className="w-100 mt-2" style={{ display: "none"}}>
                      <Col>
                        <Button variant="outline-primary" size="lg" className="w-100 mb-2 mx-2" style={{ minWidth: '120px', minHeight: '40px' }}>Domande frequenti</Button>
                      </Col>
                      <Col>
                        <Button variant="outline-secondary" size="lg" className="w-100 mb-2 mx-2" style={{ minWidth: '120px', minHeight: '40px' }}>Parla del processo di ammissione</Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                messageGroup.map((msg, index) => (
                  <div key={index} className={"msg-item" + (msg.role === "user" ? " reverse" : "")}>
                    {msg.role === "assistant" && <Avatar status="online" img={msg.avatar} />}
                    <div className="msg-body">
                      <Row className="gx-3 row-cols-auto">
                        <Col>
                        <div className="msg-bubble">
                          <div dangerouslySetInnerHTML={{ __html: marked.parse(msg.text) }}></div>
                          <span>{msg.time}</span>
                        </div>


                        </Col>
                      </Row>
                    </div>
                  </div>
                ))
              )}

              {/* Typing indicator */}
              {isTyping && (
                <div className="msg-item">
                  <div className="msg-body">
                    <Row className="gx-3 row-cols-auto">
                      <Col>
                        <div className="msg-bubble typing-indicator">
                          <span></span><span></span><span></span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </PerfectScrollbar>
            <div className="chat-body-footer">
              <div className="msg-box">
                <Form.Control
                  type="text"
                  placeholder="Write your message..."
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  onKeyPress={handleKeyPress}  // Send message on Enter key
                />
                <Link to="" className="msg-send" onClick={handleSendMessage}>
                  <i className="ri-send-plane-2-line"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
